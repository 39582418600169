<template>
  <div>
    <div class="navBtnBox">
      <a-button @click="addBody" type="primary">添加主体</a-button>
    </div>
    <div class="contentShowBox m-t1">
      <TableHeader v-model:columns="columns" @refresh="refresh"></TableHeader>
      <div class="m-t1">
        <a-table :columns="columns" @change="changePage" :pagination="pagination" :expandedRowKeys="expandedRowKeys" :data-source="dataList" @expand="expand" :loading="searchLoading" :rowKey="
            (record, index) => {
              return record.id
            }
          ">
          <!-- 是否隐藏 -->
          <template #show="{ record }">
            <a-popconfirm ref="popRef" ok-text="确定" cancel-text="取消" @confirm="confirm(record)" overlayClassName="LY" :getPopupContainer="(triggerNode) => { return triggerNode.parentNode}">
              <template #icon>
                <div class="titleTips flex ju-between al-center">
                  <span>提示</span>
                </div>
              </template>
              <template #title>
                <div v-if="record.enable == 1">确认要隐藏吗？</div>
                <div v-else>确认要显示吗？</div>
              </template>
              <a-switch :checkedValue="1" :unCheckedValue="0" size="small" :checked="record.enable" />
            </a-popconfirm>
          </template>
          <!-- 是否纸质 -->
          <template #isPaper="{ record }">
            <div v-if="record.isPaper">
              {{ record.isPaper.label}}
            </div>
          </template>
          <template #costRate="{ record }">
            <div v-if="record.subjectType === 1&&record.costRate">{{ record.costRate}}%</div>
          </template>
          <!-- 操作 -->
          <template #add="{ record }">
            <div v-if="record.level == 1" class="flex ju-between" style="color: #1890ff">
              <span class="cur-p">设为默认
                <a-popconfirm ref="popRef" ok-text="确定" cancel-text="取消" @confirm="addSwitch(record)" overlayClassName="LY" :getPopupContainer="(triggerNode) => { return triggerNode.parentNode}">
                  <template #icon>
                    <div class="titleTips flex ju-between al-center">
                      <span>提示</span>
                    </div>
                  </template>
                  <template #title>
                    <div>确认要设置默认吗？</div>
                  </template>
                  <a-switch size="small" :checked="record.isMain.value" :checkedValue="1" :unCheckedValue="0" />
                </a-popconfirm>
              </span>
              <span class="cur-p" @click="allocationTaxRate(record,index)">配置税率</span>
              <span class="cur-p" @click="editBody(record)">编辑</span>
            </div>
            <div v-if="record.level == 2" class="flex ju-between" style=" color: #1890ff">
              <span class="cur-p" @click="allocationDepartment(record,index)">配置部门</span>
              <span class="cur-p" @click="editTaxRate(record)">编辑</span>
            </div>
          </template>
        </a-table>
      </div>
      <!-- 添加主体 -->
      <FormModal :visible="visible" width="23%" :fromList="fromList" @getVal="getVal" @cancel="visible= false" :title="bodyTitle"></FormModal>
      <!-- 配置税率 -->
      <FormModal :visible="taxVisible" width="23%" :fromList="taxFromList" @getVal="taxGetVal" @cancel="taxVisible= false" :title="bodyTitle">
        <template #custom>
          <div>
            <div style="margin: 8px 0;"><span style="color:red;margin-right:4px;font-family: SimSun, sans-serif">*</span>是否支持纸质</div>
            <a-radio-group v-model:value="taxIsPaper">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </div>
        </template>
      </FormModal>
      <!-- 配置部门 -->
      <ConfigurationModal title="配置部门" ref="gurationRef" @deleteAdd="deleteAdd" @editOk="editOk" @getVal="getGuration" :columns="departmentColumns" :dataList="departmentDataList" :fromList="departmentFromList" v-model:visible="departmentVisible"></ConfigurationModal>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import TableHeader from '@/components/tableHeader/index.vue'
import FormModal from '@/components/formModal/index.vue'
import ConfigurationModal from './configurationModal.vue'
import { IfNull } from '@/utils/util.js'
import { message } from 'ant-design-vue';
import { saveSubject, subjectPage, addInvoice, getInvoiceList, deleteInvoice, IsenableReq, subjectMain, subjectCarrierType } from '@/api/finance/finance.js'
import { getOrgTreeList } from "@/api/global";
const bodyTitle = ref('')
const visible = ref(false)
const taxVisible = ref(false)
const departmentVisible = ref(false)
const isEdit = ref(false)
const searchLoading = ref(false)
const isSuccess = ref(false)
const current = ref(1)
const size = ref(10)
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showQuickJumper: true,
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '30', '40'],
  showTotal: (totals) => `共 ${totals} 条`
})
const expandedRowKeys = ref([])//记录展开的id可控制全部收起
const taxIsPaper = ref(null)
//控制分页
const changePage = (val) => {
  current.value = val.current;
  size.value = val.pageSize
  pagination.value = val
  reqSubjectPage()
}
//是否开启
const confirm = (val) => {
  IsenableReq(val.id).then(res => {
    if (res.code !== 10000) return
    message.success('设置成功')
    if (val.enable == 1) {
      val.enable = 0
    } else {
      val.enable = 1
    }

  })
}
//设置主要
const addSwitch = (item) => {
  if (item.isMain.value == 1) {
    message.warn('已经是默认，请选择其他')
    return
  }
  subjectMain(item.id).then(res => {
    if (res.code !== 10000) return
    message.success('设置成功')
    expandedRowKeys.value = []
    reqSubjectPage()
  })
}
//编辑税率
const taxRateID = ref('')
const editTaxRate = (item) => {
  bodyTitle.value = '编辑税率'
  taxRateID.value = item.id
  isEdit.value = true
  taxFromList.value[0].disabled = true
  taxFromList.value[0].value = parseInt(item.subjectName)
  taxFromList.value[1].value = item.costRate
  taxFromList.value[2].value = item.monthQuota
  taxFromList.value[3].value = item.taxItem
  taxIsPaper.value = item.isPaper.value
  taxVisible.value = true
}
//编辑主体名称
const bodyID = ref('')
const editBody = (item) => {
  bodyTitle.value = '编辑主体'
  bodyID.value = item.id
  isEdit.value = true
  fromList.value[0].value = item.subjectName
  fromList.value[1].value = item.subjectCarrierType ? item.subjectCarrierType.label : ''
  visible.value = true
}
const editOk = () => {
  reqGetInvoiceList()
}
//配置部门
const departmentID = ref(0)
const allocationDepartment = (val) => {
  departmentID.value = val.id
  reqGetInvoiceList()
  departmentVisible.value = !departmentVisible.value

}
//获取部门配置列表
const reqGetInvoiceList = () => {
  getInvoiceList(departmentID.value).then(res => {
    if (res.code !== 10000) return
    departmentDataList.value = res.data
  })
}
//添加部门
const gurationRef = ref(null)
const getGuration = (val) => {
  if (isSuccess.value) return
  if (!val.department) {
    message.warn('请选择部门')
    return
  }
  if (!IfNull(val.lineOfInvoice)) {
    message.warn('请填写月限额')
    return
  }
  isSuccess.value = true
  let orgName = ''
  departmentFromList.value[0].opt.forEach(opt => {
    if (opt.id == val.department) {
      orgName = opt.name
    }
  })
  addInvoice({
    invoiceSubjectId: departmentID.value,
    orgId: val.department,
    orgName: orgName,
    monthQuota: val.lineOfInvoice
  }).then(res => {
    isSuccess.value = false
    if (res.code !== 10000) return
    gurationRef.value.empty()
    message.success('添加成功')
    reqGetInvoiceList()
  })
}
//删除部门
const deleteAdd = (val) => {
  deleteInvoice(val.id).then((res) => {
    if (res.code !== 10000) return
    message.success('删除成功')
    reqGetInvoiceList()
  })
}
//配置税率
const allocationTaxRatID = ref(0)
const allocationTaxRate = (item) => {
  bodyTitle.value = '配置税率'
  taxFromList.value.forEach(item => {
    item.disabled = false
    item.value = ''
  })
  isEdit.value = false
  taxRateID.value = ''
  allocationTaxRatID.value = item.id
  taxIsPaper.value = null
  taxVisible.value = !taxVisible.value
}
//获取配置税率信息
const taxGetVal = (val) => {
  if (isSuccess.value) return
  if (!IfNull(val.tax) && val.tax !== 0) {
    message.warn('请填写税率')
    return
  }
  if (!IfNull(val.costRate) && val.costRate !== 0 ) {
    message.warn('请填写成本税率')
    return
  }
  if(val.costRate>20 || val.costRate<0){
    message.warn('成本税率范围在0到20')
    return
  }
  if (!IfNull(val.monthlyLimit)) {
    message.warn('请填写开票总额度')
    return
  }
  if (!IfNull(val.taxItems)) {
    message.warn('请填写税目')
    return
  }
  if (taxIsPaper.value == null) {
    message.warn('请选择是否支持纸质')
    return
  }
  isSuccess.value = true
  saveSubject({
    id: taxRateID.value,
    subjectId: allocationTaxRatID.value,
    isPaper: taxIsPaper.value,
    monthQuota: val.monthlyLimit,
    taxItem: val.taxItems,
    taxRate: val.tax,
    costRate:val.costRate,
    subjectCarrierType: 1
  }).then(res => {
    isSuccess.value = false
    if (res.code === 10000) {
      message.success('添加成功')
      taxVisible.value = false
      if (isEdit.value) {
        let str = getID(dataList.value, taxRateID.value)
        reqGetList(str)
      } else {
        reqGetList(allocationTaxRatID.value)
      }

    } else {
      message.error(res.msg)
    }
  })
}
const getID = (data, id) => {
  let str = ''
  data.forEach(item => {
    if (item.children) {
      item.children.forEach(opt => {
        if (opt.id == id) {
          str = item.id
        }
      })
    }
  })
  return str
}
//展开图标
const expandId = ref('')//记录展开哪一项的id
const expand = (item, val) => {
  if (item) {
    expandedRowKeys.value.push(val.id)
    expandId.value = val.id
    if (val.children.length !== 0) return
    reqGetList(val.id)
  } else {
    let Keys = expandedRowKeys.value.filter(RowKey => RowKey !== val.id)
    expandedRowKeys.value = Keys
  }
}

const reqGetList = (id) => {
  searchLoading.value = true
  subjectPage({
    current: 1,
    size: 200,
    subjectId: id,
    subjectType: 1
  }).then((res) => {
    searchLoading.value = false
    if (res.code !== 10000) return
    dataList.value.forEach((opt) => {
      if (opt.id == id) {
        res.data.records.forEach(item => {
          item.level = 2
        })
        opt.children = res.data.records
      }
    })
  })
}
//获取表单数据事件
const getVal = (val) => {
  if (isSuccess.value) return
  if (!IfNull(val.name)) {
    message.warn('请填写主体名称')
    return
  }
  if (!val.typeOfCarriage) {
    message.warn('请选择承运类型')
    return
  }
  isSuccess.value = true
  let subjectCarrierType = ''
  fromList.value[1].opt.forEach(opt => {
    if (opt.name == val.typeOfCarriage) {
      subjectCarrierType = opt.id
    }
  })
  saveSubject({
    id: bodyID.value,
    subjectName: val.name,
    subjectCarrierType: subjectCarrierType
  }).then(res => {
    isSuccess.value = false
    if (res.code === 10000) {
      message.success('添加成功')
      reqSubjectPage()
      visible.value = false
    } else {
      message.error(res.msg)
    }
  })
}
//刷新
const refresh = () => {
  expandedRowKeys.value = []
  reqSubjectPage()
}
//添加主体事件
const addBody = () => {
  bodyTitle.value = '添加主体'
  fromList.value[0].value = ''
  fromList.value[1].value = ''
  bodyID.value = ""
  isEdit.value = false
  visible.value = !visible.value
}
const reqSubjectPage = () => {
  searchLoading.value = true
  subjectPage({
    current: current.value,
    size: size.value,
    subjectType: 0
  }).then(res => {
    searchLoading.value = false
    if (res.code === 10000) {
      res.data.records.forEach(item => {
        item.children = []
        item.level = 1
        if (!item.isMain) {
          item.isMain = {}
          item.isMain.value = 0
        }
      })
      pagination.value.total = res.data.total
      dataList.value = res.data.records
    }
  })
}
// 主体分页
const columns = ref([
  {
    title: '主体名称',
    dataIndex: 'subjectName',
    key: 'subjectName',
    width: '17%'
  },

  {
    title: '配置人',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: '配置时间',
    dataIndex: 'createTime',
    key: 'createTime'
  },
  {
    title: '每月开票额度',
    dataIndex: 'monthQuota',
    key: 'monthQuota'
  },
  {
    title: '成本税率',
    dataIndex: 'costRate',
    key: 'costRate',
    slots: {
      customRender: 'costRate'
    }
  },
  {
    title: '本月已开额度',
    dataIndex: 'usedQuota',
    key: 'usedQuota'
  },
  {
    title: '是否启用',
    dataIndex: 'enable',
    key: 'enable',
    slots: {
      customRender: 'show'
    }
  },
  {
    title: '是否纸质',
    dataIndex: 'isPaper',
    key: 'isPaper',
    slots: {
      customRender: 'isPaper'
    }
  },
  {
    title: '税目',
    dataIndex: 'taxItem',
    key: 'taxItem'
  },
  {
    title: '操作',
    dataIndex: 'add',
    key: 'add',
    slots: {
      customRender: 'add'
    }
  }
])
// 分页数据
const dataList = ref([])
//一级表单数据
const fromList = ref([
  {
    label: '主体名称',
    name: 'name',
    type: 'input',
    placeholder: '填写',
    value: '',
    prop: 'name',
    width: '100%',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  },
  {
    label: '承运类型',
    name: 'typeOfCarriage',
    type: 'select',
    placeholder: '选择',
    value: null,
    opt: [],
    prop: 'typeOfCarriage',
    width: '100%',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  }
])

//税率表单数据
const taxFromList = ref([
  {
    label: '税率',
    name: 'tax',
    type: 'input',
    placeholder: '填写',
    disabled: false,
    value: '',
    prop: 'tax',
    unit: '%',
    width: '100%',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  },
  {
    label: '成本税率',
    name: 'costRate',
    type: 'input',
    placeholder: '填写',
    disabled: false,
    value: '',
    prop: 'costRate',
    unit: '%',
    width: '100%',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  },
  {
    label: '每月开票总额度',
    name: 'monthlyLimit',
    type: 'input',
    placeholder: '填写',
    value: '',
    prop: 'monthlyLimit',
    unit: '元',
    width: '100%',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  },
  {
    label: '税目',
    name: 'taxItems',
    type: 'input',
    placeholder: '填写',
    value: '',
    prop: 'taxItems',
    width: '100%',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  }
])
//部门表单数据
const departmentFromList = ref([
  {
    label: '部门',
    name: 'department',
    type: 'select',
    placeholder: '选择部门',
    value: null,
    prop: 'department',
    width: '100%',
    opt: [],
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  },
  {
    label: '每月开票额度',
    name: 'lineOfInvoice',
    type: 'input',
    placeholder: '填写',
    value: '',
    prop: 'lineOfInvoice',
    width: '100%',
    unit: '元',
    rules: [{ required: true, message: '请输入名称', trigger: 'blur' }]
  },
])
//部门表格内容
const departmentColumns = ref([
  {
    title: '部门',
    dataIndex: 'orgName',
    key: 'orgName',
    align: 'center',
  },
  {
    title: '每月开票额度',
    dataIndex: 'monthQuota',
    key: 'monthQuota',
    align: 'center',
  },
  {
    title: '已用额度',
    dataIndex: 'usedQuota',
    key: 'usedQuota',
    align: 'center',
  },
  {
    title: '添加人',
    dataIndex: 'creator',
    key: 'creator',
    align: 'center',
  },
  {
    title: '添加时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    key: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
])
//部门表格数据
const departmentDataList = ref([])

//获取部门
const getDepartment = () => {
  getOrgTreeList({}).then((res) => {
    if (res.code !== 10000) return;
    let treeList = res.data[0].treeList;
    departmentFromList.value[0].opt = treeList;
  });
};
onMounted(() => {
  reqSubjectPage()
  getDepartment()
  subjectCarrierType().then(res => {
    let msg = []
    res.data.forEach(item => {
      msg.push({
        name: item.label,
        id: item.value
      })
    })
    fromList.value[1].opt = msg
  })
})
</script>

<style lang="less" scoped>
@import './index.less';
</style>